<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">管理员列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center margin-10-0">
				<a target="_blank" :href="$conf.base_url + 'brigade/index.html#/passport/login'"
					style="text-decoration: none;font-weight: 800;color: #000000;cursor: pointer;">登录地址：{{$conf.base_url}}brigade/index.html#/passport/login</a>
			</div>
			<div class="d-flex flex-wrap align-items-center margin-10-0">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
				</div>
				<!-- <div class="d-flex align-items-center m-1">
					<div class="label ">单位：</div>
					<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select v-model="search.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
				</div> -->
				<router-link :to="{name:'unit.brigade.user/add'}">
					<el-button type="primary" size="mini">添加管理员</el-button>
				</router-link>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%"
				:header-cell-style="{ background: '#F2F2F2' }">
				<el-table-column align="center" label="用户ID" prop="brigade_user_id"></el-table-column>
				<el-table-column align="center" label="用户名" prop="user_name"></el-table-column>
				<el-table-column align="center" label="姓名" prop="real_name"></el-table-column>
				<el-table-column align="center" label="单位">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
						<template v-if="scope.row.second_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
						</template>
						<template v-if="scope.row.third_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="是否锁定">
					<template slot-scope="scope">
						<span style="font-weight: 800;" v-if="scope.row.is_lock == 10">未锁定</span>
						<template  v-if="scope.row.is_lock == 10">
							<el-button type="text" size="mini" @click="lock(scope.row)" class="ml-2 table-last-btn">锁定</el-button>
						</template>
						<span style="font-weight: 800;color: red;" v-if="scope.row.is_lock == 20">已锁定</span>
						<template  v-if="scope.row.is_lock == 20">
							<el-button type="text" size="mini" @click="lock(scope.row)" class="ml-2 table-last-btn">解锁</el-button>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="150">
					<template slot-scope="scope">
						<router-link
							:to="{name:'unit.brigade.user/edit',params: {brigade_user_id:scope.row.brigade_user_id}}">
							<el-button type="text" size="mini" class="table-btn">修改
							</el-button>
						</router-link>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)" class="table-last-btn">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page" background
					@current-change="pageChange" layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				lists: [],
				firstList: [],
				secondList: [],
				thirdList: [],
				search: {
					s: 'store/unit.brigade.user/index',
					keyword: '',
					page: 1,
					first_id: 0,
					second_id: 0,
					third_id: 0,
				},
			}
		},
		created() {
			this.getList();
			this.getUnitList();
		},
		methods: {
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data
			
				}).catch(err => {});
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					let data = res.data.data
					this.lists = data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该用户吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/unit.brigade.user/delete',
							brigade_user_id: data.brigade_user_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
			lock(data) {
				let title= "您确定锁定用户吗？";
				if(data.is_lock == 20){
					title = "您确定解锁用户吗？"
				}
				this.$confirm(title, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/unit.brigade.user/lock',
							brigade_user_id: data.brigade_user_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>
</style>
